<template>
    <el-dialog
        :title="$t('calendar.confirm_undo_schedule')"
        :visible.sync="modalConfirmCalendar"
        :before-close="closeModal"
        width="600px"
        top="40px"
        :close-on-click-modal="false"
        class="modal-confirm-events"
        @closed="$emit('closed')"
    >
        <el-row>
            <el-col :span="20" :offset="2">
                <p class="mb-1">
                    {{ $t('calendar.all_calendar_events_up_to') }}:
                </p>
                <el-date-picker
                    v-model="confirmDate"
                    :clearable="false"
                    align="center"
                    :editable="false"
                    :picker-options="pickerOptions"
                    type="daterange"
                />

                <el-switch
                    v-model="confirmType"
                    class="mb-6 mt-12"
                    active-color="#13ce66"
                    inactive-color="#20a0ff"
                    :inactive-value="true"
                    :active-value="false"
                    :inactive-text="$t('calendar.all')"
                    :active-text="$t('calendar.for_specific')"
                    :width="120"
                />

                <el-form label-position="top">
                    <el-form-item :label="$t('calendar.employees')">
                        <EmployeesDropdown
                            ref="employeesDropdown"
                            withoutQuerySearch
                            multiple
                            customClass="w-full mt-3"
                            :disabled="confirmType"
                            @selected="employeesFilterChanged"
                        />
                    </el-form-item>
                    <!-- <el-form-item :label="$t('calendar.managers')">
                        <el-select
                            v-model="selectedManagers"
                            :placeholder="$t('calendar.managers')"
                            multiple
                            filterable
                            class="dialog-select"
                            :disabled=confirmType"
                        >
                            <el-option
                                    v-for="manager in managersList"
                                    :key="manager.id"
                                    :label="manager.name + ' ' + manager.surname"
                                    :value="manager.id">
                            </el-option>
                        </el-select>
                    </el-form-item> -->
                    <el-form-item :label="$t('calendar.clients')">
                        <ClientsDropdown
                            ref="clientsDropdown"
                            withoutQuerySearch
                            multiple
                            class="w-full mt-3"
                            :disabled="confirmType"
                            @selected="clientsFilterChanged"
                        />
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>

        <span slot="footer" class="dialog-footer">
            <el-button @click="closeModal">{{ $t('common.cancel') }}</el-button>
            <el-button type="primary" :loading="$wait.is('confirming')" :disabled="buttonDisabled" @click="undoConfirmationEvents">
                {{ $t('calendar.undo_confirmation') }}
            </el-button>
            <el-button type="primary" :loading="$wait.is('confirming')" :disabled="buttonDisabled" @click="confirmEvents">
                {{ $t('common.confirm') }}
            </el-button>
        </span>
    </el-dialog>
</template>
<script>
export default {
    components: {
        EmployeesDropdown: () => import(/* webpackChunkName: "EmployeesDropdown" */ '@/components/EmployeesDropdown'),
        ClientsDropdown:   () => import(/* webpackChunkName: "ClientsDropdown" */ '@/components/ClientsDropdown'),
    },

    props: {
        modalConfirmCalendar: {
            type:    Boolean,
            default: false,
        },
    },

    data() {
        return {
            confirmDate: [
                this.$dayjs(),
                this.$dayjs().add(7, 'days'),
            ],
            confirmType:       true,
            selectedEmployees: [],
            selectedClients:   [],
            pickerOptions:     {
                firstDayOfWeek: 1,
                disabledDate:   date => date.getTime() < this.$dayjs().subtract(1, 'day'),
            },
        };
    },

    computed: {
        buttonDisabled() {
            return !this.userCan('update calendar events') || (!this.confirmType && this.selectedEmployees.length === 0 && this.selectedClients.length === 0);
        },
    },

    watch: {
        confirmType() {
            this.$refs.employeesDropdown.resetSelection();
            this.$refs.clientsDropdown.resetSelection();
            this.selectedEmployees = [];
            this.selectedClients = [];
        },
    },

    methods: {
        async confirmEvents() {
            this.$wait.start('confirming');
            try {
                await axios.post(`${process.env.VUE_APP_CORE_API_URL}/services/calendar/confirm_calendar`, {
                    confirm_date_start: this.$dayjs(this.confirmDate[0]).unix(),
                    confirm_date_end:   this.$dayjs(this.confirmDate[1]).unix(),
                    confirm_type_all:   this.confirmType,
                    employees_uuid:     this.selectedEmployees,
                    clients_uuid:       this.selectedClients,
                    // manager_id: this.selectedManagers,
                });
                this.$wait.end('confirming');
                this.$notify.success({ title: this.$t('common.success') });
                this.$emit('refresh');
                this.closeModal();
            } catch (error) {
                this.$wait.end('confirming');
                this.closeModal();
            }
        },

        async undoConfirmationEvents() {
            this.$wait.start('confirming');
            try {
                await axios.post(`${process.env.VUE_APP_CORE_API_URL}/services/calendar/undo_confirm_calendar`, {
                    confirm_date_start: this.$dayjs(this.confirmDate[0]).unix(),
                    confirm_date_end:   this.$dayjs(this.confirmDate[1]).unix(),
                    confirm_type_all:   this.confirmType,
                    employees_uuid:     this.selectedEmployees,
                    clients_uuid:       this.selectedClients,
                    // manager_id: this.selectedManagers,
                });
                this.$wait.end('confirming');
                this.$notify.success({ title: this.$t('common.success') });
                this.$emit('refresh');
                this.closeModal();
            } catch (error) {
                this.$wait.end('confirming');
                this.closeModal();
            }
        },

        clientsFilterChanged(clients) {
            this.selectedClients = clients;
        },

        employeesFilterChanged(employees) {
            this.selectedEmployees = employees;
        },

        closeModal() {
            this.$emit('close');
        },
    },
};
</script>
<style>
.modal-confirm-events .el-date-editor {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    width: 100% !important;
    justify-content: flex-start !important;
}
.modal-confirm-events .el-range__close-icon {
    display: none !important;
}
.modal-confirm-events .el-picker-panel__shortcut {
    line-height: 26px;
}
.modal-confirm-events .el-range-input {
    width: 50%;
}
</style>
